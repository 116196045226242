
import React from "react";
import { Field } from "react-final-form";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const SearchableSelectValidatorElement = ({
  name,
  label,
  suggestions,
  helperText,
  disabled,
  autoComplete,
  onChange,
  validate,
  ...rest
}) => {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        const isValid = !(meta.touched && meta.error);

        return (
          <Autocomplete
            disablePortal
            options={suggestions}
            getOptionLabel={(option) => option.label || ""}
            isOptionEqualToValue={(option, value) => option.value === value}
            value={
              suggestions.find((option) => option.value === input.value) || null
            }
            onChange={(event, newValue) => {
                input.onChange(newValue ? newValue.value : "");
                onChange && onChange(newValue ? newValue.value : "");
            }
            }
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                {...input}
                {...params}
                label={label}
                error={!isValid}
                helperText={!isValid ? meta.error : helperText}
                autoComplete={autoComplete}
                {...rest}
              />
            )}
          />
        );
      }}
    </Field>
  );
};

export default SearchableSelectValidatorElement;
