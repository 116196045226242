import * as actionTypes from "./types";

import userService from "../services/UserServices";
import Enums from "../utils/Enums";
import CiamResponseCode from "../utils/CiamResponseCode";
import { eClaimActions } from "../actions";
import UserService from "../services/UserServices";
import Util from "../utils/Util.js";

export const userActions = {
  refreshToken,
  login,
  logout,
  getEmployeeInfo,
  getGaPolicyInfo,
  getBannerList,
  getResetPasswordOTP,
  getResetLinkValidity,
  putResetPasswordOTP,
  forgotPassword,
  updateToken,
  agreeTnc,
  getUserInfo,
  CIAMLogin,
  getRSA,
  getOtp,
  postOTP,
  getSumAssuredDetails,
  emulateLogin,
  setAfterLoginPopupVisibility
};

function login(username, password) {
  return dispatch => {
    dispatch(reset());
    dispatch(request({ username }));

    return userService
      .login({
        username: encodeURIComponent(username),
        password: encodeURIComponent(password)
      })
      .then(
        response => {
          if (response.success) {
            const tokenObj = Util.jwt_decode(response.data.access_token);
            if (tokenObj.ars && tokenObj.ars.includes(Enums.ACCESSIBLE_ROLES)) {
              dispatch(success(response.data));
            } else {
              response["code"] = 400;
              response["customMessage"] =
                "The username or password you entered is incorrect.";
              dispatch(failure(response));
              return Promise.reject();
            }
          } else {
            if (response.code === 400)
              response["customMessage"] =
                "The username or password you entered is incorrect.";
            dispatch(failure(response));
          }
          return Promise.resolve(response);
        },
        error => {
          dispatch(failure(error));
          return Promise.reject(error);
        }
      );
  };

  function request(user) {
    return { type: actionTypes.USER_LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: actionTypes.USER_LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: actionTypes.USER_LOGIN_FAILURE, error };
  }
  function reset() {
    return { type: actionTypes.APP_RESET };
  }
}

function getUserInfo() {
  return dispatch => {
    return userService.me().then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.USER_GET_INFO_SUCCESS,
            payload: response.data
          });
          dispatch(getUserPolicy());
          dispatch(eClaimActions.fetchClaimant());
          dispatch(getBannerList());
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({ type: actionTypes.USER_GET_INFO_ERROR });
        return Promise.reject(error);
      }
    );
  };
}

function getUserPolicy() {
  return dispatch => {
    return userService.policy().then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.USER_GET_POLICY_SUCCESS,
            payload: response.data
          });
          dispatch(getGaPolicyInfo(response.data.policy_no));
          dispatch(getEmployeeInfo());
          dispatch(getSumAssuredDetails());
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({ type: actionTypes.USER_GET_POLICY_ERROR });
        return Promise.reject(error);
      }
    );
  };
}

function getGaPolicyInfo(policyNo) {
  return dispatch => {
    UserService.getGaPolicyInfo(policyNo).then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.USER_GET_GA_POLICY_FULFILLED,
            payload: response.data
          });
        }
        return Promise.resolve(response);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function getBannerList() {
  return (dispatch, getState) => {
    const state = getState();
    return userService.getBannerList(state.user.id).then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.EMPLOYEE_GET_BANNER_SUCCESS,
            payload: response.data
          });
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({ type: actionTypes.EMPLOYEE_GET_BANNER_ERROR });
        return Promise.reject(error);
      }
    );
  };
}

function getEmployeeInfo() {
  return (dispatch, getState) => {
    const state = getState();
    return userService.getEmployee(state.user.id).then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.EMPLOYEE_GET_INFO_SUCCESS,
            payload: response.data
          });
        }
        return Promise.resolve(response);
      },
      error => {
        dispatch({ type: actionTypes.EMPLOYEE_GET_INFO_ERROR });
        return Promise.reject(error);
      }
    );
  };
}

function getSumAssuredDetails() {
  return (dispatch, getState) => {
    const state = getState();
    return userService.getSumAssured(state.user.policy.policy_no).then(
      response => {
        if (response.success) {
          dispatch({
            type: actionTypes.EMPLOYEE_COVERAGE_SUM_ASSURED_SUCCESS,
            payload: response.data
          });
          return Promise.resolve(response);
        }
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function logout() {
  userService.logout().catch(err => {
    console.log(err.defaultMessage);
  });
  return { type: actionTypes.USER_LOGOUT };
}

function getResetPasswordOTP(code) {
  return dispatch => {
    return userService.getResetPasswordOTP(code).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function getResetLinkValidity(code) {
  return dispatch => {
    return userService.getResetLinkValidity(code).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function putResetPasswordOTP(params) {
  return dispatch => {
    return userService.putResetPasswordOTP(params).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function forgotPassword(username) {
  return dispatch => {
    return userService.forgotPassword({ username }).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function agreeTnc(params) {
  return dispatch => {
    return userService.agreeTnc(params).then(
      resp => {
        dispatch(updateToken(resp.data));
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function updateToken(data) {
  return { type: actionTypes.USER_UPDATE_TOKEN, payload: data };
}

function refreshToken(token) {
  return {
    type: actionTypes.USER_REFRESH_TOKEN,
    payload: userService.refreshToken(token)
  };
}

function CIAMLogin(username, password, randomKey, txnId, cnonce, chash) {
  return (dispatch, getState) => {
    dispatch(reset());
    dispatch(request({ username }));

    return userService
      .CIAMLogin({
        username: encodeURIComponent(username),
        password: encodeURIComponent(password),
        randomKey: randomKey,
        txnId: txnId,
        cnonce,
        chash
      })
      .then(
        response => {
          const tokenObj = Util.jwt_decode(response.data.access_token);
          if (response.success) {
            if (tokenObj.ars && tokenObj.ars.includes(Enums.ACCESSIBLE_ROLES)) {
              dispatch(success(response.data));
            } else {
              response["code"] = 400;
              response["customMessage"] = Util.validateLogin(
                CiamResponseCode.UNAUTHORIZED
              );
              dispatch(failure(response));
              return Promise.reject(response);
            }
          }
          return Promise.resolve(response);
        },
        error => {
          let errorCode = error.message.split(CiamResponseCode.CIAM_KEYWORD);
          error["customMessage"] = Util.validateLogin(errorCode[0]);
          dispatch(failure(error));
          return Promise.reject(error);
        }
      );
  };

  function request(user) {
    return { type: actionTypes.USER_LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: actionTypes.USER_LOGIN_SUCCESS, user, username };
  }
  function failure(error) {
    return { type: actionTypes.USER_LOGIN_FAILURE, error };
  }
  function reset() {
    return { type: actionTypes.APP_RESET };
  }
}

function getRSA(username, password) {
  return dispatch => {
    dispatch(reset());
    dispatch(request({ username }));

    return userService.getRSA().then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };

  function request(user) {
    return { type: actionTypes.USER_LOGIN_REQUEST, user };
  }

  function reset() {
    return { type: actionTypes.APP_RESET };
  }
}

function getOtp(username, password, randomKey, txnId) {
  return dispatch => {
    return userService.getOtp({ username, password, randomKey, txnId }).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        return Promise.reject(error);
      }
    );
  };
}

function postOTP(otp, password, randomKey, txnId) {
  return dispatch => {
    return userService.postOTP({ otp, password, randomKey, txnId }).then(
      resp => {
        return Promise.resolve(resp);
      },
      error => {
        error["customMessage"] = Util.validateOtpLogin(error.message);
        return Promise.reject(error);
      }
    );
  };
}

function emulateLogin(emulateRef) {
  return dispatch => {
    dispatch(reset());
    dispatch(request());

    return userService.emulateLoginV2(encodeURIComponent(emulateRef)).then(
      response => {
        const tokenObj = Util.jwt_decode(response.data.access_token);
        if (response.success) {
          if (tokenObj.ars && tokenObj.ars.includes(Enums.ACCESSIBLE_ROLES)) {
            console.log("respp emulate");
            console.log(response.data);
            dispatch(success(response.data));
          } else {
            response["code"] = 400;
            response["customMessage"] = "Pce emulate employee failed";
            dispatch(failure(response));
            return Promise.reject(response);
          }
        }
        return Promise.resolve(response);
      },
      error => {
        error["customMessage"] = "Pce emulate employee error";
        dispatch(failure(error));
        return Promise.reject(error);
      }
    );
  };

  function request() {
    return { type: actionTypes.USER_LOGIN_REQUEST };
  }
  function success(data) {
    return { type: actionTypes.USER_EMULATE_LOGIN_SUCCESS, payload: data };
  }
  function failure(error) {
    return { type: actionTypes.USER_LOGIN_FAILURE, error };
  }
  function reset() {
    return { type: actionTypes.APP_RESET };
  }
}

function setAfterLoginPopupVisibility(visible) {
  return {
    type: actionTypes.GET_AFTER_LOGIN_POPUP,
    payload: visible
  };
}
