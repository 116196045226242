import { withStyles } from "@mui/styles";
import React, { useEffect, useMemo, useState } from "react";
import { injectIntl } from "react-intl";

import DatePickerValidatorElement from "../../../../components/form/DatePickerValidatorElement";
import TextValidatorElement from "../../../../components/form/TextValidatorElement";
import SearchableSelectValidatorElement from "../../../../components/form/SearchableSelectValidatorElement";

import DiagnosisOptions from "./DiagnosisListSimplified.json";
import Enums from "../../../../utils/Enums";
import PceService from "../../../../services/PceService";
import { composeValidators, invalidCharacters, required } from "../../../../utils/validator";
import config from "../../../../config/config";
import Util from "../../../../utils/Util";
import moment from "moment";

const DIAGNOSIS_ID_OTHERS = "780";
const OLD_DIAGNOSIS_ID_OTHERS = "OTH";
const CLINIC_ID_OTHERS = "50001047";

const ClaimEditDiagnosisSection = ({
  claim,
  isPCEClient,
  handleChange,
  handleDateChange,
  formatDate,
  readOnly,
  intl,
  classes,
  loggedUser
}) => {
  const {
    clinic_id: clinicId,
    clinic_name: clinicName,
    claim_type: claimType,
    date_of_consultation: dateOfConsultation,
    diagnosis_id: diagnosisId,
    diagnosis_name: diagnosisName,
  } = claim;
  const { coverage_start_date } = loggedUser;

  const [clinicOptions, setClinicOptions] = useState([]);

  useEffect(() => {
    if (!isPCEClient) return;

    const fetchData = async () => {
      const { data = [] } = await PceService.fetchProviderClinicsOptions();
      if (!data.some((e) => e.value === CLINIC_ID_OTHERS)) {
        data.push({ value: CLINIC_ID_OTHERS, label: "Others" });
      }
      setClinicOptions(data);
    };

    fetchData();
  }, [isPCEClient]);

  useEffect(() => {
    if (!isPCEClient || !readOnly) return;
    if (OLD_DIAGNOSIS_ID_OTHERS !== diagnosisId) return;
    handleChange("diagnosis_id")(DIAGNOSIS_ID_OTHERS);
  }, [isPCEClient, readOnly, diagnosisId]);

  const handleClinicIdChange = useMemo(() => {
    const handleRawClinicIdChange = handleChange("clinic_id");
    const handleClinicNameChange = handleChange("clinic_name");
    return (selected) => {
      handleRawClinicIdChange(selected);
      handleClinicNameChange(selected ? selected.label : "");
    };
  }, [handleChange]);

  const showDiagnosis = (claimType) =>
    [
      Enums.CLAIM_TYPE.INPATIENT,
      Enums.CLAIM_TYPE.MATERNITY,
      Enums.CLAIM_TYPE.OUTPATIENT_GP,
      Enums.CLAIM_TYPE.OUTPATIENT_SP,
      Enums.CLAIM_TYPE.OUTPATIENT,
    ].includes(claimType);

  const handleDiagnosisIdChange = useMemo(() => {
    const handleRawDiagnosisIdChange = handleChange("diagnosis_id");
    const handleDiagnosisNameChange = handleChange("diagnosis_name");
    return (selected) => {
      handleRawDiagnosisIdChange(selected);
      handleDiagnosisNameChange(selected ? selected.label : "");
    };
  }, [handleChange]);

  const validateDateOfConsultation = (value) => {
    if (value) {
      let coverageStartDate = null;
      if (!Util.isEmpty(coverage_start_date)) {
        coverageStartDate = moment(coverage_start_date, "DD/MM/YYYY");
      }
      const parsedCoverageStartDate = moment(coverageStartDate, "DD/MM/YYYY");
      const parsedValue =  moment(value, "DD/MM/YYYY");

      if (parsedValue.isBefore(parsedCoverageStartDate)) {
        return "The date of visit must be after the start of coverage.";
      }

      const daysDifference = moment().diff(moment(value, "DD/MM/YYYY"), "days")

      if (daysDifference > config.MAX_SUBMISSION_DAY) {
        return config.submissionMaxDaysErrorMessage ?? "The value must not more than MAX_SUBMISSION_DAY"
      }

      return;
    }

    return;
  }

  return (
    <div>
      <DatePickerValidatorElement
        disabled={readOnly}
        fullWidth
        name="date_of_consultation"
        autoComplete="off"
        clearable
        label="Date Of Visit"
        disableFuture
        value={formatDate(dateOfConsultation)}
        handleDateChange={handleDateChange("date_of_consultation")}
        format="DD/MM/YYYY"
        animateYearScrolling
        keyboard
        variant="standard"
        validate={composeValidators(required, validateDateOfConsultation)}
      />
      {isPCEClient && (
        <div>
          <SearchableSelectValidatorElement
            disabled={readOnly}
            value={clinicId || ""}
            onChange={handleClinicIdChange}
            name="clinic_id"
            id="clinic_id"
            variant="standard"
            label="Service Provider / Clinic"
            autoComplete="off"
            suggestions={clinicOptions}
            validate={required}
          />
          {clinicId === CLINIC_ID_OTHERS && (
            <TextValidatorElement
              disabled={readOnly}
              autoComplete="off"
              fullWidth
              variant="standard"
              className={classes.textField}
              inputProps={{ maxLength: 200 }}
              label="Service Provider / Clinic Others"
              handleChange={handleChange("clinic_name")}
              name="clinic_name"
              value={clinicName || ""}
              validate={composeValidators(required, invalidCharacters)}
            />
          )}
        </div>
      )}
      {showDiagnosis(claimType) && (
        <SearchableSelectValidatorElement
          disabled={readOnly}
          value={diagnosisId || ""}
          onChange={handleDiagnosisIdChange}
          name="diagnosis_id"
          id="diagnosis_id"
          label="Diagnosis"
          autoComplete="off"
          suggestions={DiagnosisOptions}
          validate={required}
        />
      )}
      {diagnosisId === DIAGNOSIS_ID_OTHERS && (
        <TextValidatorElement
          disabled={readOnly}
          autoComplete="off"
          fullWidth
          className={classes.textField}
          inputProps={{ maxLength: 200 }}
          label="Diagnosis Others"
          handleChange={handleChange("diagnosis_name")}
          name="diagnosis_name"
          value={diagnosisName || ""}
          validate={composeValidators(required, invalidCharacters)}
        />
      )}
    </div>
  );
};

const styles = (theme) => ({});

export default injectIntl(withStyles(styles)(ClaimEditDiagnosisSection));
