import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import classNames from "classnames";
import React, { Component } from "react";

class Banner extends Component {
  renderButton = type => {
    const { classes, banner } = this.props;
    switch (type) {
      case "docdoc":
        return (
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.call(banner.button.first);
              }}
              className={classNames(
                classes.buttonFirst,
                classes.buttonOnBanner
              )}
            >
              {banner.button.first}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={this.props.buttonClickControl.docdoc.requestACallback}
              className={classNames(
                classes.buttonSecond,
                classes.buttonOnBanner
              )}
            >
              {banner.button.second}
            </Button>
          </div>
        );
      default:
        return;
    }
  };

  call = numberPhone => {
    let usefulNumberPhone = numberPhone.replace(/-/g, "");
    window.location.href = `tel:${usefulNumberPhone}`;
  };

  render() {
    const { classes, banner } = this.props;
    const bannerImage = `url(/static/media/${banner.background.split('/').pop()})`
    return (
      <div>
        <Grid
          container
          className={classes.banner}
          spacing={"0px"}
          style={{
            backgroundImage:bannerImage
              
          }}
        >
          <Grid item xs={12} sm={6} md={5} className={classes.hideIfDesktop}>
            <p className={classes.logoHolderTop}>
              {banner.logo.map((img, index) => {
                return (
                  <img
                    key={index}
                    src={img}
                    alt="Logo"
                    className={classes.logoOnTop}
                  />
                );
              })}
            </p>
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <p className={classes.logoHolderRight}>
              {banner.logo.map((img, index) => {
                return (
                  <img
                    key={index}
                    src={img}
                    alt="Logo"
                    className={classes.logoOnRight}
                  />
                );
              })}
            </p>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  logo: {
    paddingLeft: "20px"
  },
  buttonOnBanner: {
    minWidth: 175,
    marginTop: 30,
    marginRight: 15
  },
  hideIfDesktop: {
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block"
    }
  },
  logoOnTop: {
    height: 40
  },
  logoOnRight: {
    height: 50
  },
  logoHolderRight: {
    textAlign: "right",
    marginTop: 0,
    display: "block",
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  buttonFirst: {
    marginRight: 10,
    padding: "10px 25px",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      marginRight: "auto"
    }
  },
  buttonSecond: {
    backgroundColor: "transparent",
    border: `1px solid ${theme.colors.primary.red}`,
    color: theme.colors.primary.red,
    boxShadow: "none",
    padding: "10px 25px"
  },
  space: {
    padding: "20px"
  },
  banner: {
    padding: 40,
    minHeight: 240,
    backgroundSize: "cover",
    backgroundPosition: "100% 100%",
    backgroundRepeat: "no-repeat"
  },
  bannerHeader: {
    fontSize: 37,
    [theme.breakpoints.down("xs")]: {
      fontSize: 25
    }
  },
  bannerDesc: {
    lineHeight: 1.5,
    fontSize: 21,
    [theme.breakpoints.down("xs")]: {
      fontSize: 19
    }
  },
  subInformation: {
    padding: 40,
    paddingTop: 20,
    backgroundColor: theme.colors.blackScale.black50
  },
  subTitle: {
    fontSize: 24,
    marginTop: 0,
    marginBottom: 10
  },
  subDesc: {
    color: theme.colors.blackScale.black80,
    paddingTop: 5,
    fontSize: 15
  }
});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Banner));