import { withStyles } from "@mui/styles";
import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { injectIntl } from "react-intl";
import { renderTextField } from "./CustomReduxFormComponent";
import { Form, Field } from "react-final-form";
import { composeValidators, isEmail, isTrim, required } from "../utils/validator";

class RequestRedeemPromo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      promoCode: "",
      contact: {
        name: "",
        email: "",
        phone: ""
      }
    };
  }

  handleSubmit = (values) => {
    this.setState({
      open: false
    });
    const { promoCode } = this.state;
    this.props.callback(values, promoCode);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  componentWillReceiveProps(newProps) {
    this.setState({ open: newProps.open });
  }

  componentDidMount() {
    this.setState({
      open: this.props.open,
      promoCode: this.props.promoCode
    });
  }

  onEnter = () => {
    this.setState({
      contact: {
        name: this.props.initData ? this.props.initData.contact_name : "",
        email: this.props.initData ? this.props.initData.contact_email : "",
        phone: this.props.initData ? this.props.initData.contact_no : ""
      },
      promoCode: this.props.promoCode
    });
  };

  render() {
    const { classes, intl } = this.props;
    return (
      <div>
        <Dialog
          onEnter={this.onEnter}
          maxWidth="md"
          classes={{
            paper: classes.dialogWrapper
          }}
          open={this.state.open || false}
          onClose={this.handleClose}
          aria-labelledby="redeem-promo-dialog"
        >
          <DialogTitle id="redeem-promo-dialog">
            {intl.formatMessage({ id: "benefit.redeemPromo.title" })}
          </DialogTitle>
          <DialogContent className={classes.rootDialog}>
            <DialogContentText className={classes.topContent}>
              {intl.formatMessage({
                id: "benefit.redeemPromo.details"
              })}
            </DialogContentText>

            <Form 
              onSubmit={this.handleSubmit}
              initialValues={this.state.contact}
              render={({ handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={"24px"}>
                      <Grid item xs={12}>
                        <Field
                          fullWidth={true}
                          name="name"
                          id="contactName"
                          label="Contact name"
                          autoComplete="[off]"
                          component={renderTextField}
                          placeholder="Contact Name"
                          validate={composeValidators(required, isTrim)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          fullWidth={true}
                          name="email"
                          id="email"
                          label="Your email"
                          autoComplete="[off]"
                          placeholder="Email Address"
                          component={renderTextField}
                          validate={composeValidators(required, isTrim, isEmail)}
                        />                          
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          fullWidth={true}
                          name="phone"
                          id="phone"
                          label="Your phone"
                          type="number"
                          autoComplete="[off]"
                          placeholder="Phone Number"
                          component={renderTextField}
                          validate={composeValidators(required, isTrim)}
                        />
                      </Grid>
                    </Grid>

                    <DialogTitle style={{ width: "100%", textAlign: "center" }}>
                      {intl.formatMessage({ id: "benefit.redeemPromo.policy.title" })}
                    </DialogTitle>
                    <Typography component="p" style={{ whiteSpace: "pre-line" }}>
                      {intl.formatMessage({
                        id: "benefit.redeemPromo.policy.details"
                      })}
                      {intl.formatMessage({
                        id: "benefit.redeemPromo.policy.privacy"
                      })}
                    </Typography>
                    <DialogActions
                      classes={{
                        root: classes.dialogActionRoot,
                        action: classes.dialogAction
                      }}
                    >
                      <Button
                        color="secondary"
                        onClick={this.handleClose}
                        className={classes.cancelButton}
                        variant="raised"
                        fullWidth={true}
                      >
                        Cancel
                      </Button>

                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        autoFocus
                        fullWidth={true}
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </form>
                )
              }}
            />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const styles = theme => ({
  root: {},
  dialogWrapper: {
    padding: "15px 25px"
  },
  rootDialog: {
    overflowX: "hidden"
  },
  dialogContentHeight: {
    height: "65%"
  },
  dialogActionRoot: {
    display: "flex",
    justifyContent: "space-between"
  },
  dialogAction: {
    width: "45%"
  },
  cancelButton: {
    backgroundColor: theme.palette.default.main,
    border: `2px solid ${theme.palette.secondary.main}`,
    fontWeight: 500
  },
  bottomContent: {
    paddingTop: 50
  },
  topContent: {
    paddingBottom: 20
  }
});

export default injectIntl(withStyles(styles, { withTheme: true })(RequestRedeemPromo));