import React from "react";
import { Field } from "react-final-form";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { withStyles } from "@mui/styles";
import classNames from "classnames";
import { Grid, Radio, Typography } from "@mui/material";
import TextValidatorElement from "./TextValidatorElement";

const RadioButtonValidatorElement = ({
  name,
  label,
  infoLabel,
  value,
  handleRadioChange,
  handleChange,
  amountExcludeGst,
  radioType,
  options,
  helperText,
  required,
  validate,
  classes,
  ...rest
}) => {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        const isValid = !(meta.touched && meta.error);
        return (
          <>
            <FormControl fullWidth margin="normal" error={!isValid}>
              <label
                htmlFor={name}
                style={{
                  fontSize: "12px",
                  marginTop: "10px",
                  left: "-12px",
                  color: "grey",
                }}
                className={classNames(!isValid && classes.labelError)}
              >
                {label}
              </label>
              <Grid container rowSpacing={1} columnSpacing={3} xs={8} item>
                <Grid item style={{ display: "flex", alignItems: "center" }}>
                    <Radio
                        //   disabled={readOnly}
                        {...rest}
                        checked={value == true}
                        onChange={event => {
                            input.onChange(event.target.value);
                            handleRadioChange && handleRadioChange(event, radioType);
                        }}
                        value={true}
                        name={input.name}
                        color="primary"
                    />
                    <Typography sx={{ fontSize: "12px", fontWeight: 500 }}>
                        Yes
                    </Typography>
                    <Radio
                        //   disabled={readOnly}
                        {...rest}
                        checked={value == false}
                        onChange={event => {
                            input.onChange(event.target.value);
                            handleRadioChange && handleRadioChange(event, radioType);
                        }}
                        value={false}
                        name={input.name}
                        color="primary"
                    />
                    <Typography sx={{ fontSize: "12px", fontWeight: 100 }}>
                        No
                    </Typography>

                    {value && radioType == "gst_indicator" ? (
                        <Grid xs={15} marginLeft={20}>
                            <TextValidatorElement
                                {...input}
                                {...rest}
                                disabled={true}
                                autoComplete="[off]"
                                fullWidth
                                className={classes.textField}
                                variant="standard"
                                label="Amount Excluding GST"
                                handleChange={() => handleChange("amount_exclude_gst")}
                                name="amount_exclude_gst"
                                value={amountExcludeGst}
                            />
                        </Grid>
                    ) : (
                        ""
                    )}
                </Grid>
              </Grid>
              <FormHelperText className={classNames(classes.info)}>
                {infoLabel}
              </FormHelperText>
              <FormHelperText className={classNames(!isValid && classes.error)}>
                {meta.touched && meta.error ? meta.error : helperText}
              </FormHelperText>
            </FormControl>
          </>
        );
      }}
    </Field>
  );
};

const styles = (theme) => ({
  error: {
    marginLeft: "0px",
    color: "#f44336",
  },
  labelError: {
    color: "#f44336",
  },
  inputMargin: {
    marginTop: 20,
  },
  info: {
    marginLeft: "0px",
    color: "#000",
    fontWeight: "500",
  },
});

export default withStyles(styles, { withTheme: true })(
  RadioButtonValidatorElement
);
