import Util from "./Util";

const required = (value) => value ? undefined : "Required";

const isSelectedRequired = (value) => !value || value === "-" ? "Required" : undefined;

const requiredPaymentMethod = (value) => value === "-" || !value ? "Required" : undefined;

const isOnlyNumbersAndDecimalPoint = (value) => isNaN(value) || parseFloat(value) <= 0 ? "Only numbers and decimal point (.) are allowed in this field. Please remove any other special characters and alphabets." : undefined;

const isInvoiceNumberValid = (value) => !Util.isInvoiceNumberValid(value) ? "Please ensure that only alphanumeric character. spaces ( ), and hyphen (-) are used in this field" : undefined;

const composeValidators = (...validators) => value => validators.reduce((error, validator) => error || validator(value), undefined);

const invalidCharacters = (value) => !Util.specialCharacterCheck(value) ? "Invalid character(s) ( & > < ' \" ) detected." : undefined;

const isMaxCharValid = (value) => value.length >= 200 ? "Max 200 characters length exceeded." : undefined;

const isTrim = (value) => value.trim() === "" ? "This field can't be blank" : undefined;

const isEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
    if (emailRegex.test(value)) return;
    return "Invalid email format";
}

export {
    required, 
    composeValidators,
    isInvoiceNumberValid,
    isOnlyNumbersAndDecimalPoint,
    invalidCharacters,
    requiredPaymentMethod,
    isMaxCharValid,
    isTrim,
    isEmail,
    isSelectedRequired
}