import "./banner.css";
import "./benefit-content.css";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import LinearProgress from "@mui/material/LinearProgress";
import { Link } from "react-router-dom";
import { withStyles } from "@mui/styles";
import assets from "../../../../assets";
import Banner from "./banner";
import CallBackService from "../../../../services/CallBackService";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import LoadingPopup from "../../../../components/LoadingPopup";
import React, { Component } from "react";
import RequestRedeemPromo from "../../../../components/RequestRedeemPromo";

import PDFViewer from "../../MyDocuments/components/pdfViewer";

import SimpleMessageDialog from "../../../../components/SimpleMessageDialog";
import SimpleConfirmDialog from "../../../../components/SimpleConfirmDialog";

class BenefitInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      triggerResultDialog: false,
      triggerLoadingPopup: false,
      openConfirmDialog: false,

      /**
       * Se default response is success
       */
      responseType: "success",
      initData: null,
      promoCode: ""
    };

    this.redeemPromoSubmitForm = (contact, promoCode) => {
      const data = {
        name: contact.name,
        email: contact.email,
        phone: contact.phone,
        redirect: false,
        promo_code: promoCode
      };

      this.setState({
        triggerLoadingPopup: true,
        RedeemPromoDialog: false
      });

      CallBackService.sendEmailRequestCallBack(
        data,
        this.state.benefitInfo.key
      ).then(
        response => {
          if (response.success) {
            this.setState({ triggerLoadingPopup: false });
            this.showPopupResult(this.state.benefitInfo.key, "success");
          }
        },
        error => {
          this.setState({ triggerLoadingPopup: false });
          this.showPopupResult(this.state.benefitInfo.key, "error");
        }
      );
    };

    this.redeemPromoRedirect = (initData, promoCode, promoURL) => {
      const data = {
        name: initData.contact_name,
        email: initData.contact_email,
        phone: "",
        promo_code: promoCode,
        redirect: true
      };

      CallBackService.sendEmailRequestCallBack(
        data,
        this.state.benefitInfo.key
      ).then(
        response => {
          if (response.success) {
            this.setState({ triggerLoadingPopup: false });
            window.open(
              promoURL,
              // "https://classpass.com/corporate/singapore/prudentialsg",
              "_blank",
              "noopener noreferrer"
            );
          }
        },
        error => {
          this.setState({ triggerLoadingPopup: false });
          this.showPopupResult(this.state.benefitInfo.key, "error");
        }
      );
    };
  }

  /**
   * After sent email success.
   * Show the popup success.
   * We need to close all the popups are opening
   */
  showPopupResult = (partnerKey, responseType) => {
    this.setState({
      // Show success dialog
      triggerResultDialog: true,
      // Response type: success || error
      responseType: responseType,
      // Name of partner: Validus, DocDoc, Wellteq
      partnerName: this.state.benefitInfo.name,
      // Close all popups are opening
      RequestACallbackDialog: false
    });
  };

  handleRedeemPromo = (promo, benefitInfo) => {
    //debugger;
    if (benefitInfo.url !== "") {
      this.setState({
        ...this.state,
        openConfirmDialog: true,
        promoCode: promo,
        promoURL: benefitInfo.url
      });
    }
    // else if (benefitInfo.key === "uob_travel") {
    //   this.setState({
    //     ...this.state,
    //     openConfirmDialog: true,
    //     promoCode: promo.code
    //   });
    // }
    else {
      this.setState({
        ...this.state,
        RedeemPromoDialog: true,
        promoCode: promo,
        promoURL: benefitInfo.url
      });
    }
  };

  closeCofirmDialog = () => {
    this.setState({ openConfirmDialog: false });
  };

  responseConfirmDialog = () => {
    this.closeCofirmDialog();
    this.redeemPromoRedirect(
      this.state.initData,
      this.state.promoCode,
      this.state.promoURL
    );
  };

  handleMoreInfo = (docUrl, fileName) => {
    this.setState({
      RedeemPromoDialog: false,
      open: true,
      fileName: fileName,
      pdf: docUrl
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  closePopupResult = () => {
    this.setState({ triggerResultDialog: false });
  };

  componentDidMount() {
    this.setState({
      benefitInfo: assets.benefits.details.default[this.props.type]
    });
    /**
     * Check if we have data of current user
     * Then set it to initData for Dialog Request Call Back
     */
    /**
     * TODO
     * Warning: `value` prop on `input` should not be null. Consider using an empty string to clear the component or `undefined` for uncontrolled components.
     */
    if (this.props.user) {
      let firstname = this.props.user.firstname
          ? this.props.user.firstname
          : "",
        lastname = this.props.user.lastname ? this.props.user.lastname : "",
        email = this.props.user.email ? this.props.user.email : "",
        fullName = this.props.user.fullName ? this.props.user.fullName : "";

      this.setState({
        initData: {
          contact_name: fullName
            ? fullName
            : firstname.length > 0 || lastname.length > 0
            ? firstname + " " + lastname
            : "",
          contact_email: email,
          /**
           * We don't have number phone for Employee
           * They have to enter it.
           */
          contact_no: ""
        }
      });
    }
  }

  render() {
    const { classes, intl } = this.props;
    const { benefitImages } = assets;
    return (
      <div className={classes.root}>
        {this.state.benefitInfo !== undefined ? (
          <div>
            <Banner banner={this.state.benefitInfo} />
            <div className={classes.content}>
              <div className={classes.info}>
                <p className={classes.header}>
                  {this.state.benefitInfo.content.header}
                </p>
                <p className={classes.desc}>
                  {this.state.benefitInfo.content.description}
                </p>
              </div>

              <Grid container alignItems={"stretch"} spacing={"16px"}>
                {this.state.benefitInfo.promo.length > 0 &&
                  this.state.benefitInfo.promo.map((promo, key) => (
                    <Grid item xs={12} md={4} key={promo.code}>
                      <Card
                        className={`${classes.card} ${classes.contentContainer}`}
                      >
                        <CardContent>
                          <List className={classes.highlight}>
                            <ListItem className={classes.highlight}>
                              <ListItemText
                                primary={
                                  promo && (
                                    <div>
                                      <span className={classes.highlightTitle}>
                                        {promo.title}
                                      </span>
                                      <span
                                        className={classes.highlightContent}
                                      >
                                        <p>{promo.details}</p>
                                      </span>
                                    </div>
                                  )
                                }
                              />
                            </ListItem>
                          </List>
                        </CardContent>
                        <div className={classes.buttonContainerFixed}>
                          <Button
                            className={`${classes.button} 
                            ${classes.buttonContainer}
                            ${classes.buttonLeft}`}
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              this.handleRedeemPromo(
                                promo,
                                this.state.benefitInfo
                              )
                            }
                          >
                            Redeem
                          </Button>

                          {assets.VASdoc.map((doc, index) => {
                            const { fileName, file, promoCode } = doc;

                            if (promoCode && promoCode === promo.code) {
                              const docUrl =
                                window.location.href.split("/")[0] +
                                "/static/media/" +
                                file.split('/').pop();

                              return (
                                <Button
                                  key={index}
                                  className={`${classes.button} 
                                  ${classes.buttonContainer}
                                  ${classes.buttonRight}  `}
                                  variant="contained"
                                  color="secondary"
                                  onClick={() =>
                                    this.handleMoreInfo(docUrl, fileName)
                                  }
                                >
                                  More Info
                                </Button>
                              );
                            }
                          })}
                        </div>
                      </Card>
                    </Grid>
                  ))}
              </Grid>

              <Grid container className={classes.bottomContent}>
                <Grid item>
                  <div className={classes.backHolder}>
                    <Link
                      to={"/auth/employeeBenefit"}
                      className={classes.backLink}
                    >
                      <span className={classes.backButton}>
                        Back to My Benefits
                      </span>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        ) : (
          <LinearProgress />
        )}

        <RequestRedeemPromo
          initData={this.state.initData}
          promoCode={this.state.promoCode}
          open={this.state.RedeemPromoDialog}
          callback={this.redeemPromoSubmitForm.bind(this)}
        />
        <PDFViewer
          open={this.state.open}
          handleClose={this.handleClose}
          pdf={this.state.pdf}
          fileName={this.state.fileName}
        />
        <SimpleMessageDialog
          type={this.state.responseType}
          name={`request-callback-` + this.state.responseType}
          title={intl.formatMessage({
            id: `benefit.redeemPromo.${this.state.responseType}.title`
          })}
          description={intl.formatMessage(
            {
              id: `benefit.redeemPromo.${this.state.responseType}.description`
            },
            { partnerName: this.state.partnerName }
          )}
          closeButtonText={intl.formatMessage({ id: "popup.button.ok" })}
          closeHandler={this.closePopupResult}
          open={this.state.triggerResultDialog}
        />
        <SimpleConfirmDialog
          name="leave-confirm-dialog"
          title="You’re leaving the PruWorks Portal."
          description="We will redirect you to our partner’s website. Click ok to continue."
          closeButtonText="Cancel"
          okButtonText="OK"
          closeHandler={this.closeCofirmDialog}
          okHandler={this.responseConfirmDialog.bind(this)}
          open={this.state.openConfirmDialog}
        />
        <LoadingPopup
          name={this.props.type}
          open={this.state.triggerLoadingPopup}
        />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      //marginTop: 64
    }
  },
  iconSuccess: {
    color: `${theme.colors.error}`,
    fontSize: "3rem"
  },
  iconError: {
    color: `${theme.colors.error}`,
    fontSize: "3rem"
  },
  content: {
    padding: "40px",
    backgroundColor: theme.colors.secondary.white,
    zIndex: 100
  },
  card: {
    height: "100%",
    paddingLeft: "20px"
  },
  info: {
    color: "#6B6A6D",
    fontSize: 18
  },
  header: {
    marginTop: 0,
    marginBottom: 4,
    color: "#414141",
    fontWeight: 700
  },
  desc: {
    textAlign: "justify",
    marginTop: 0,
    marginBottom: 30,
    lineHeight: 1.5
  },
  highlights: {
    backgroundColor: theme.colors.blackScale.black60,
    padding: "20px 40px"
  },
  highlight: {
    paddingLeft: 0
  },
  highlightTitleMedia: {
    height: 60,
    width: 60
  },
  highlightTitle: {
    fontSize: "24px",
    //color:"#414141",
    color: "#848689",
    fontWeight: 700
  },
  highlightContent: {
    lineHeight: "30px",
    //fontSize: "16px",
    color: "#6B6A6D",
    color: "#333",
    fontSize: 18
  },
  backHolder: {
    paddingTop: "50px",
    display: "flex",
    alignItems: "center"
  },
  backLink: {
    color: "red",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "12px"
  },
  backButton: {
    backgroundColor: "white",
    fontWeight: "bold",
    border: `1px solid ${theme.colors.blackScale.black60}`,
    padding: "10px 25px",
    borderRadius: "70px"
  },
  button: {
    minWidth: 150
  },
  bottomContent: {
    paddingBottom: 65
  },
  claimContainer: {
    marginTop: 30
  },
  claimContent: {
    backgroundColor: theme.colors.blackScale.black50,
    "&:last-child": {
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  claimHeader: {
    backgroundColor: theme.colors.blackScale.black70
  },
  claimTitle: {
    color: theme.colors.secondary.white,
    fontSize: 18
  },
  claimIconContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center"
  },
  claimDoc: {
    maxWidth: 96
  },
  step: {
    paddingBottom: 15
  },
  stepsContent: {
    padding: theme.spacing.unit
  },
  stepHeader: {
    fontWeight: 500
  },
  stepDescription: {
    fontWeight: 500
  },
  buttonContainer: {
    margin: 20,
    minWidth: 100
  },
  /* buttonLeft: {
    float: "left" ,
    marginRight:5
  },
  buttonRight: {
    float: "right",
    marginLeft:5
  }, */
  contentContainer: {
    height: "100%",
    width: "100%",
    borderCollapse: "collapse",
    display: "table"
  },
  buttonContainerFixed: {
    display: "table-row",
    verticalAlign: "bottom",
    height: "1px"
  }
});

function mapStateToProps(state) {
  return { user: state.user };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(BenefitInfo)));