import { withStyles } from "@mui/styles";
import React from "react";
import Typography from "@mui/material/Typography";
import Icon from "@mui/material/Icon";
import { injectIntl } from "react-intl";
import DescriptionIcon from "@mui/icons-material/Description";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import DropZone from "../DropZone";
import Enums from "../../../../utils/Enums";
import { isImage } from "./ClaimEditMain";

const MAX_FILE_SIZE = 20971520;
// const CLAIM_EXTENSION = ".jpg,.jpeg,.pdf,.doc,.docx";

const ClaimEditSupportingDocumentsSection = ({
  claim,
  eclaim,
  setClaim,
  readOnly,
  clearUploadFileError,
  classes
}) => {
  const { claim_status: claimStatus, error, images } = claim;

  const removeImage = (name, idx) => event => {
    const images = claim[name].slice(0);
    images.splice(idx, 1);
    setClaim(claim => ({
      ...claim,
      [name]: images
    }));
    //try to clear error message
    clearUploadFileError(idx);
  };

  const preAddImage = name => files => {
    setClaim(claim => ({
      ...claim,
      error: ""
    }));

    if (files && files.length > 0) {
      files.forEach(file => {
        var reader = new FileReader();
        reader.onload = e => {
          const data = e.target.result;

          if (isImage(file.name)) {
            var image = new Image();
            image.src = data;
            // Validate the File Height and Width.
            image.onload = function() {
              var width = image.width;
              const temp = claim[name] || [];
              temp.push({
                name: file.name,
                size: {
                  width
                },
                data
              });

              setClaim(claim => ({ ...claim, [name]: temp }));

              return true;
            };
          } else {
            const temp = claim[name] || [];
            temp.push({
              name: file.name,
              data
            });

            setClaim(claim => ({ ...claim, [name]: temp }));
          }
        };
        reader.readAsDataURL(file);
      });
    }
  };

  const onDropRejected = files => {
    const firstFile = files[0];
    if (firstFile.size > MAX_FILE_SIZE) {
      setClaim(claim => ({
        ...claim,
        error: "The file you are trying to upload has exceeded our 20MB limit"
      }));
      return;
    }
  };

  const onIncorrectFilename = files => {
    setClaim(claim => ({
      ...claim,
      error: files[0]
    }));
    return;
  };

  const safeParse = (msg) => {
    try {
      return typeof msg === "string" && msg.startsWith("{") ? JSON.parse(msg) : [msg];
    } catch (error) {
      console.error("JSON parsing error:", error, "Data:",msg)
    }
  }

  const uploadFileErrors = eclaim.uploadFileErrors;

  const closeButton = (readOnly, idx) => {
    if (readOnly) {
      return null;
    }
    return (
      <Icon className={classes.inlineIcon} onClick={removeImage("images", idx)}>
        close
      </Icon>
    );
  };
  return (
    <div>
      {(!claimStatus ||
        claimStatus === Enums.CLAIMS_STATUS.SUBMITTED ||
        claimStatus === Enums.CLAIMS_STATUS.EDIT_REQUESTED) && (
        <div style={{ marginTop: 10, marginBottom: 20 }}>
          <Typography
            className={classes.heading}
            variant="subheading"
            color="inherit"
          >
            Supporting Documents
          </Typography>
          {uploadFileErrors.map(val => {
            if (val !== null) {
              return safeParse(val).map((msg, index) => {
                return (
                  <Typography key={index} className={classes.errorMessage}>
                    {msg}
                  </Typography>
                );
              });
            }
          })}
          {images.map((img, idx) => {
            return (
              <div style={{ marginTop: 15 }} key={idx}>
                <div style={{ position: "relative" }} key={idx}>
                  {isImage(img.name) && [
                    <Typography
                      width="100%"
                      className={classes.inlineHeader}
                      key={`img_header_${idx}`}
                    >
                      <InsertPhotoIcon className={classes.inlineIconFile} />
                      {img.name}
                      {closeButton(readOnly, idx)}
                    </Typography>,
                    <div
                      key={`img_content_${idx}`}
                      style={{
                        padding: 5,
                        backgroundColor: "#e4e4e4a3"
                      }}
                    >
                      <img src={img.data} alt="" width="100%" />
                    </div>
                  ]}
                  {!isImage(img.name) && (
                    <Typography width="100%" className={classes.inlineHeader}>
                      <DescriptionIcon className={classes.inlineIconFile} />
                      <span>{img.name}</span>
                      {closeButton(readOnly, idx)}
                    </Typography>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}

      {!readOnly && (
        <DropZone
          // accept={CLAIM_EXTENSION}
          multiple={true}
          maxSize={MAX_FILE_SIZE}
          onDropRejected={onDropRejected}
          onIncorrectFilename={onIncorrectFilename}
          onDropAccepted={preAddImage("images")}
          errorMessage={error}
          description="Take or Upload photo of supporting document"
          supportTitle="File name must only contain alphanumeric, underscore(_), space( ) and hyphen(-)."
          supportTitle2="Only .pdf, .jpg/.jpeg, and .png files are allowed. File size must not exceed 10MB. "
        />
      )}
    </div>
  );
};
const styles = theme => ({
  inlineIcon: {
    position: "absolute",
    top: 5,
    right: 5
  },
  inlineHeader: {
    backgroundColor: "#e4e4e4",
    padding: 8
  },
  inlineIconFile: {
    verticalAlign: "middle",
    paddingRight: 5
  },
  boxHeader: {
    position: "relative",
    borderBottom: "1px solid #d3d3d3"
  },
  boxIcon: {
    backgroundColor: "#d87474",
    color: "white",
    padding: 8,
    display: "inline-block",
    verticalAlign: "middle"
  },
  boxIconApprove: {
    backgroundColor: "#18c494"
  },
  errorMessage: {
    color: theme.palette.error.main
  }
});

export default injectIntl(
  withStyles(styles)(ClaimEditSupportingDocumentsSection)
);
