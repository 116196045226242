import React from "react";
import { Field } from "react-final-form";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { withStyles } from "@mui/styles";
import classNames from "classnames";

const styles = (theme) => ({
  labelError: {
    color: theme.palette.error.main,
  },
  error: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0)
  },
  inputMargin: {
    marginTop: theme.spacing(2),
  },
  info: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(0)
  },
});

const TextValidatorElement = ({
  name,
  label,
  helperText,
  classes,
  required,
  addMargin,
  infoLabel,
  handleChange,
  validate,
  ...rest
}) => {
  const handleCustomChange = (event, input) => {
    if (handleChange) {
      handleChange(event)
    }

    input.onChange(event);
  }

  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        const isError = (meta.touched || meta.submitFailed) && meta.error;
        return (
          <FormControl fullWidth margin="normal" error={isError}>
            <InputLabel
              htmlFor={name}
              className={classNames(isError && classes.labelError)}
              style={{ top: "-25px", left: "-12px" }}
            >
              {label}
            </InputLabel>
            <TextField
              id={name}
              {...rest}
              {...input}
              required={required}
              classes={{
                input: addMargin ? classNames(classes.inputMargin) : null,
              }}
              onChange={(event) => handleCustomChange(event, input)}
            />
            {infoLabel && (
              <FormHelperText className={classNames(classes.info)}>
                {infoLabel}
              </FormHelperText>
            )}
             
            <FormHelperText className={classNames(isError && classes.error)}>
              {isError ? meta.error : helperText}
            </FormHelperText>
          </FormControl>
        );
      }}
    </Field>
  );
};

export default withStyles(styles)(TextValidatorElement);
