import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage/session";
import { persistStore, persistReducer } from "redux-persist";

import reducer from "./reducers";

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const middleware = composeEnhancers(applyMiddleware(thunk));

const persistConfig = {
  key: "reduxStore",
  storage
};
const persistedReducer = persistReducer(persistConfig, reducer);
/* eslint-enable */

export const store = createStore(persistedReducer, middleware);
export const persistor = persistStore(store);
