import "./banner.css";
import "./benefit-content.css";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import LinearProgress from "@mui/material/LinearProgress";
import { Link } from "react-router-dom";
import { withStyles } from "@mui/styles";
import assets from "../../../../assets";
import Avatar from "@mui/material/Avatar";
import Banner from "./banner";
import CallBackService from "../../../../services/CallBackService";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import LoadingPopup from "../../../../components/LoadingPopup";
import React, { Component } from "react";
import RequestCallBackModal from "../../../../components/RequestCallBackModal";
import SimpleMessageDialog from "../../../../components/SimpleMessageDialog";

class BenefitInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      [`trigger${this.props.type}RequestACallbackDialog`]: false,
      triggerResultDialog: false,
      triggerLoadingPopup: false,
      /**
       * Se default response is success
       */
      responseType: "success",
      initData: null
    };

    this.buttonClickControl = {
      docdoc: {
        requestACallback: () => {
          this.setState({
            [`trigger${this.props.type}RequestACallbackDialog`]: true
          });
        },
        getFinancingRequest: () => {
          window.open(
            "http://sme.validus.sg/",
            "_blank",
            "noopener noreferrer"
          );
        }
      }
    };

    this.callbackSubmitForm = {
      docdoc: {
        submit: data => {
          this.setState({
            triggerLoadingPopup: true,
            [`trigger${this.props.type}RequestACallbackDialog`]: false
          });
          CallBackService.sendEmailRequestCallBack(
            data,
            this.state.benefitInfo.key
          ).then(
            response => {
              if (response.success) {
                this.setState({ triggerLoadingPopup: false });
                this.showPopupResult(this.state.benefitInfo.key, "success");
              }
            },
            error => {
              this.setState({ triggerLoadingPopup: false });
              this.showPopupResult(this.state.benefitInfo.key, "error");
            }
          );
        }
      }
    };
  }

  /**
   * After sent email success.
   * Show the popup success.
   * We need to close all the popups are opening
   */
  showPopupResult = (partnerKey, responseType) => {
    this.setState({
      // Show success dialog
      triggerResultDialog: true,
      // Response type: success || error
      responseType: responseType,
      // Name of partner: Validus, DocDoc, Wellteq
      partnerName: this.state.benefitInfo.name,
      // Close all popups are opening
      [`trigger${this.props.type}RequestACallbackDialog`]: false
    });
  };

  closePopupResult = () => {
    this.setState({ triggerResultDialog: false });
  };

  componentDidMount() {
    this.setState({
      benefitInfo: assets.benefits.details.default[this.props.type]
    });
    /**
     * Check if we have data of current user
     * Then set it to initData for Dialog Request Call Back
     */
    /**
     * TODO
     * Warning: `value` prop on `input` should not be null. Consider using an empty string to clear the component or `undefined` for uncontrolled components.
     */
    if (this.props.user) {
      let firstname = this.props.user.firstname
          ? this.props.user.firstname
          : "",
        lastname = this.props.user.lastname ? this.props.user.lastname : "",
        email = this.props.user.email ? this.props.user.email : "",
        fullName = this.props.user.fullName ? this.props.user.fullName : "";

      this.setState({
        initData: {
          contact_name: fullName
            ? fullName
            : firstname.length > 0 || lastname.length > 0
            ? firstname + " " + lastname
            : "",
          contact_email: email,
          /**
           * We don't have number phone for Employee
           * They have to enter it.
           */
          contact_no: ""
        }
      });
    }
  }

  render() {
    const { classes, intl } = this.props;
    const { benefitImages } = assets;
    return (
      <div className={classes.root}>
        {this.state.benefitInfo !== undefined ? (
          <div>
            <Banner
              banner={this.state.benefitInfo}
              buttonClickControl={this.buttonClickControl}
            />
            <div className={classes.content}>
              <Grid container alignItems={"stretch"} spacing={"16px"}>
                <Grid item xs={12} md={4}>
                  <Card className={classes.card}>
                    <CardContent>
                      <List>
                        <ListItem className={classes.highlight}>
                          <Avatar
                            className={classes.highlightTitleMedia}
                            src={benefitImages.serviceImg}
                          />
                          <ListItemText
                            primary={
                              <p className={classes.highlightTitle}>Service</p>
                            }
                          />
                        </ListItem>
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card className={classes.card}>
                    <CardContent>
                      <List>
                        <ListItem className={classes.highlight}>
                          <Avatar
                            className={classes.highlightTitleMedia}
                            src={benefitImages.benefitsImg}
                          />
                          <ListItemText
                            primary={
                              <p className={classes.highlightTitle}>Benefits</p>
                            }
                          />
                        </ListItem>
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card className={classes.card}>
                    <CardContent>
                      <List>
                        <ListItem className={classes.highlight}>
                          <Avatar
                            className={classes.highlightTitleMedia}
                            src={benefitImages.resultsImg}
                          />
                          <ListItemText
                            primary={
                              <p className={classes.highlightTitle}>Results</p>
                            }
                          />
                        </ListItem>
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid container className={classes.bottomContent}>
                <Grid item>
                  <div className={classes.backHolder}>
                    <Link to={"/auth/dashboard"} className={classes.backLink}>
                      <span className={classes.backButton}>
                        Back to Dashboard
                      </span>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        ) : (
          <LinearProgress />
        )}
        <RequestCallBackModal
          initData={this.state.initData}
          policyMessageID={`benefit.requestACallBack.${this.props.type}.policy`}
          open={this.state[`trigger${this.props.type}RequestACallbackDialog`]}
          callback={this.callbackSubmitForm.docdoc}
        />
        <SimpleMessageDialog
          type={this.state.responseType}
          name={`request-callback-` + this.state.responseType}
          title={intl.formatMessage({
            id: `benefit.requestACallBack.${this.state.responseType}.title`
          })}
          description={intl.formatMessage(
            {
              id: `benefit.requestACallBack.${this.state.responseType}.description`
            },
            { partnerName: this.state.partnerName }
          )}
          closeButtonText={intl.formatMessage({ id: "popup.button.ok" })}
          closeHandler={this.closePopupResult}
          open={this.state.triggerResultDialog}
        />
        <LoadingPopup
          name={this.props.type}
          open={this.state.triggerLoadingPopup}
        />
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 64
    }
  },
  iconSuccess: {
    color: `${theme.colors.error}`,
    fontSize: "3rem"
  },
  iconError: {
    color: `${theme.colors.error}`,
    fontSize: "3rem"
  },
  content: {
    padding: "40px",
    backgroundColor: theme.colors.secondary.white,
    zIndex: 100
  },
  card: {
    height: "100%",
    paddingLeft: "20px"
  },
  info: {
    color: "#6B6A6D",
    fontSize: 18
  },
  header: {
    marginTop: 0,
    marginBottom: 4,
    color: "#414141",
    fontWeight: 700
  },
  desc: {
    textAlign: "justify",
    marginTop: 0,
    marginBottom: 30,
    lineHeight: 1.5
  },
  highlights: {
    backgroundColor: theme.colors.blackScale.black60,
    padding: "20px 40px"
  },
  highlight: {
    paddingLeft: 0
  },
  highlightTitleMedia: {
    height: 60,
    width: 60
  },
  highlightTitle: {
    fontSize: "24px"
  },
  highlightContent: {
    lineHeight: "30px",
    fontSize: "16px"
  },
  backHolder: {
    paddingTop: "50px",
    display: "flex",
    alignItems: "center"
  },
  backLink: {
    color: "red",
    fontWeight: "bold",
    textDecoration: "none",
    fontSize: "12px"
  },
  backButton: {
    backgroundColor: "white",
    fontWeight: "bold",
    border: `1px solid ${theme.colors.blackScale.black60}`,
    padding: "10px 25px",
    borderRadius: "70px"
  },
  bottomContent: {
    paddingBottom: 65
  },
  claimContainer: {
    marginTop: 30
  },
  claimContent: {
    backgroundColor: theme.colors.blackScale.black50,
    "&:last-child": {
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  claimHeader: {
    backgroundColor: theme.colors.blackScale.black70
  },
  claimTitle: {
    color: theme.colors.secondary.white,
    fontSize: 18
  },
  claimIconContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center"
  },
  claimDoc: {
    maxWidth: 96
  },
  step: {
    paddingBottom: 15
  },
  stepsContent: {
    padding: theme.spacing.unit
  },
  stepHeader: {
    fontWeight: 500
  },
  stepDescription: {
    fontWeight: 500
  }
});

function mapStateToProps(state) {
  return { user: state.user };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withStyles(styles, { withTheme: true })(BenefitInfo)));