import React, { Component } from "react";
import { renderRoutes } from "../../../utils/Routes";
import { connect } from "react-redux";
import { withStyles } from "@mui/styles";
class Dashboard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <main>
          {/* -- Render Child pages */}
          {renderRoutes(this.props.routes)}
        </main>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    height: "100%"
  }
});

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(Dashboard));
