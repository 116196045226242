import React from "react";
import { Field } from "react-final-form";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import classNames from "classnames";
import { withStyles } from "@mui/styles";

const SelectValidatorElement = ({
  name,
  label,
  suggestions = [],
  helperText,
  classes,
  resetValue,
  handleChange,
  validate,
  ...rest
}) => {
  return (
    <Field name={name} validate={validate}>
      {({ input, meta }) => {
        const isValid = !(meta.touched && meta.error);
        return (
          <FormControl
            fullWidth
            margin="normal"
            error={!isValid}
          >
            <InputLabel
              htmlFor={name}
              className={classNames(!isValid && classes.labelError)}
              style={{ left: "-12px" }}
            >
              {label}
            </InputLabel>
            <Select
              {...rest}
              {...input}
              onChange={(e) => {
                const value = e.target.value;
                if (
                  value &&
                  suggestions.filter((c) => c.value === value).length === 0
                ) {
                  resetValue && resetValue();
                } else {
                  input.onChange(e);
                  handleChange && handleChange(e);
                }
              }}
            >
              {suggestions.map((option, idx) => (
                <MenuItem key={idx} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText className={classNames(!isValid && classes.error)}>
              {meta.touched && meta.error ? meta.error : helperText}
            </FormHelperText>
          </FormControl>
        );
      }}
    </Field>
  );
};

const styles = (theme) => ({
  labelError: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0)
  },
  error: {
    color: theme.palette.error.main,
    marginLeft: theme.spacing(0)
  },
});

export default withStyles(styles)(SelectValidatorElement);
