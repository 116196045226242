import "./App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { IntlProvider } from "react-intl";
import muiTheme from "../../themes";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import React, { Component } from "react";
import { getMessages } from "../../intl";
import routes from "../../routes";
import { LocalizationProvider as MuiDatePickerLocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import config from "../../config/config";
import { createGenerateClassName, StylesProvider } from "@mui/styles";
import { pageLoadTime } from "../../utils/Helper";

const generateClassName = createGenerateClassName({
  productionPrefix: "tw"
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: "en"
    };
  }

  componentDidMount() {
    let pathname = window.location.href;
    const gtmId = this.getGTMId(); // determine which GTM ID is used based on the portal
    (function(w, d, s, l, i, n) {
      w[l] = w[l] || [];
      w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", gtmId);
    pageLoadTime(pathname);
  }

  componentDidUpdate(prevProps, prevState) {
    let pathname = window.location.href;
    pageLoadTime(pathname);
  }

  getGTMId() {
    const { href } = window.location;
    let nonProductionUrlStrings = ["dev", "sit", "stg", "uat"];

    // check the url for me portal
    if (nonProductionUrlStrings.some(url => href.includes(url))) {
      return "GTM-NLX3WZDM"; // GTM id for non production employee portal
    } else {
      return "GTM-W4W7NPJ3"; // GTM id for production employee portal
    }
  }

  render() {
    let i18Messages = getMessages(this.state.locale);

    // set time period for the maintenance page
    let currentDateTime = new Date().getTime();
    let startDateTime = parseInt(config.startDateTime);
    let endDateTime = parseInt(config.endDateTime);
    let showMaintenace = false;
    const maintenancePath = "/maintenance";

    if (startDateTime < currentDateTime && endDateTime > currentDateTime) {
      showMaintenace = true;
    }

    return (
      <>
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${this.getGTMId()}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>
        <IntlProvider locale={this.state.locale} messages={i18Messages}>
          <MuiDatePickerLocalizationProvider dateAdapter={AdapterMoment}>
            <BrowserRouter>
              <StyledEngineProvider injectFirst>
                <StylesProvider generateClassName={generateClassName} injectFirst>
                  <ThemeProvider theme={muiTheme}>
                    <CssBaseline />
                    <div className="App">
                      <Switch>
                        {/* Define routes manually */}
                        {routes.map((route, index) => (
                          <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => (
                              <route.component {...props} routes={route.routes} />
                            )}
                          />
                        ))}
                      </Switch>
                      {/* Redirects for Maintenance */}
                      {showMaintenace && config.maintenanceEnabled ? (
                        <Redirect to="/maintenance" />
                      ) : maintenancePath === window.location.pathname ? (
                          <Redirect to="/" />
                      ) : null }
                    </div>
                  </ThemeProvider>
                </StylesProvider>
              </StyledEngineProvider>
            </BrowserRouter>
          </MuiDatePickerLocalizationProvider>
      </IntlProvider>
      </>
    );
  }
}

export default App;
