import React from "react";
import { Field } from "react-final-form";
import { DatePicker } from "@mui/x-date-pickers";
import FormHelperText from "@mui/material/FormHelperText";
import { withStyles } from "@mui/styles";
import classNames from "classnames";

const DatePickerValidatorElement = ({
  name,
  helperText,
  classes,
  handleDateChange,
  validate,
  ...rest
}) => {
  return (
    <Field name={name} validate={validate} >
      {({ input, meta }) => {
        const isValid = !(meta.touched && meta.error);

        return (
          <div>
            <DatePicker
              {...input}
              {...rest}
              onChange={(value) => { 
                input.onChange(value);
                handleDateChange && handleDateChange(value);
              }}
              slotProps={{
                textField: {
                  variant: "standard",
                  error: !isValid,
                },
              }}
            />
            <FormHelperText
              className={classNames(!isValid && classes.error)}
            >
              {meta.touched && meta.error ? meta.error : helperText}
            </FormHelperText>
          </div>
        );
      }}
    </Field>
  );
};

const styles = (theme) => ({
  error: {
    color: theme.palette.error.main,
  },
});

export default withStyles(styles, { withTheme: true })(
  DatePickerValidatorElement
);
