import React from "react";
import { Form, Field } from "react-final-form";
import Typography from "@mui/material/Typography";
import { renderTextField } from "../../components/CustomReduxFormComponent";
import SimpleControlDialog from "../../components/SimpleControlDialog";

const required = (value) => (value ? undefined : "Required");

class OtpDialog extends React.Component {
  componentDidUpdate(prevProps) {
    // Reset form when dialog is opened
    if (!prevProps.show && this.props.show) {
      this.formApi?.reset();
    }
  }

  handleSubmit = (values) => {
    this.props.submitHandler(values.otp);
  };

  render() {
    const { show, description, closeHandler, submitError, submitErrorMessage } =
      this.props;

    return (
      <Form
        onSubmit={this.handleSubmit}
        render={({ handleSubmit, form, valid }) => {
          this.formApi = form; // Save the form API to reset form
          return (
            <SimpleControlDialog
              show={show}
              title="Enter OTP"
              description={description}
              onClose={closeHandler}
              closeHandler={closeHandler}
              onSubmit={handleSubmit}
              okButtonText="Submit"
              closeButtonText="Cancel"
              valid={valid}
            >
              <Field
                name="otp"
                component={renderTextField}
                autoFocus
                margin="dense"
                autoComplete="off"
                label="OTP"
                type="text"
                validate={required}
              />
              {submitError && (
                <Typography
                  variant="caption"
                  style={{ color: "red", whiteSpace: "pre-line", textAlign: "left" }}
                >
                  {submitErrorMessage}
                </Typography>
              )}
            </SimpleControlDialog>
          );
        }}
      />
    );
  }
}

export default OtpDialog;
