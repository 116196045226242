const ieCompatible = navigator.userAgent.indexOf("MSIE") > -1 || navigator.userAgent.indexOf("rv:") > -1
  ? {
      MuiInput: {
        root: {
          height: 31
        }
      },
      MuiDialogActions: {
        root: {
          justifyContent: "center"
        }
      }
    }
  : {};

export default ieCompatible;
