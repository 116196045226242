import React from "react";
import { Route, Switch} from "react-router-dom";

export const renderRoutes = (routes) => {
    if (!routes) return null;

    return (
        <Switch>
            {routes.map(({ path, exact, component: RouteComponent, routes: nestedRoutes }, index) => (
                <Route
                    key={index}
                    path={path}
                    exact={exact}
                    render={(props) => (
                        // Pass nested routes to the component
                        <RouteComponent {...props} routes={nestedRoutes} />
                    )}
                />
            ))}
        </Switch>
    )
}