import { withStyles } from "@mui/styles";
import React, { Component } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { injectIntl } from "react-intl";
import { Form, Field } from "react-final-form";
import { renderTextField } from "./CustomReduxFormComponent";
import { composeValidators, isEmail, isTrim, required } from "../utils/validator";

class RequestCallBackModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      contact: {
        name: "",
        email: "",
        phone: ""
      }
    };
  }

  handleSubmit = (values, form) => {
    this.props.callback.submit(values);
    form.reset();
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  componentWillReceiveProps(newProps) {
    this.setState({ open: newProps.open });
  }

  componentDidMount() {
    this.setState({ open: this.props.open });
  }

  onEnter = () => {
    console.info("Init default value", this.props.initData);
    this.setState({
      contact: {
        name: this.props.initData ? this.props.initData.contact_name : "",
        email: this.props.initData ? this.props.initData.contact_email : "",
        phone: this.props.initData ? this.props.initData.contact_no : ""
      }
    });
  };

  render() {
    const { classes, intl } = this.props;
    return (
      <div>
        <Dialog
          onEnter={this.onEnter}
          maxWidth="xs"
          classes={{
            paper: classes.dialogWrapper
          }}
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="request-callback-dialog"
        >
          <DialogTitle id="request-callback-dialog">
            {intl.formatMessage({ id: "benefit.requestACallBack.title" })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {intl.formatMessage({
                id: "benefit.requestACallBack.confirmDetails"
              })}
            </DialogContentText>
              <Form 
                onSubmit={this.handleSubmit}
                initialValues={this.state.contact}
                render={({ handleSubmit }) => {
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={"24px"}>
                        <Grid item xs={12}>
                          <Field
                            fullWidth={true}
                            name="name"
                            id="name"
                            label="Your name"
                            autoComplete="[off]"
                            component={renderTextField}
                            validate={composeValidators(required, isTrim)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            fullWidth={true}
                            name="email"
                            id="email"
                            label="Your email"
                            autoComplete="[off]"
                            component={renderTextField}
                            validate={composeValidators(required, isTrim, isEmail)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            fullWidth={true}
                            name="phone"
                            id="phone"
                            label="Your phone"
                            autoComplete="[off]"
                            component={renderTextField}
                            validate={composeValidators(required, isTrim)}
                          />
                        </Grid>
                      </Grid>
                      <Typography component="p" className={classes.bottomContent}>
                        {intl.formatMessage({ id: this.props.policyMessageID })}
                      </Typography>
                      <DialogActions
                        classes={{
                          root: classes.dialogActionRoot,
                          action: classes.dialogAction
                        }}
                      >
                        <Button
                          color="secondary"
                          onClick={this.handleClose}
                          className={classes.cancelButton}
                          variant="contained"
                          fullWidth={true}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          autoFocus
                          fullWidth={true}
                        >
                          Submit
                        </Button>
                      </DialogActions>
                    </form>
                  )
                }}
              />
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const styles = theme => ({
  root: {},
  dialogWrapper: {
    padding: "15px 25px"
  },
  dialogActionRoot: {
    display: "flex",
    justifyContent: "space-between"
  },
  dialogAction: {
    width: "45%"
  },
  cancelButton: {
    backgroundColor: theme.palette.default.main,
    border: `2px solid ${theme.palette.secondary.main}`,
    fontWeight: 500
  },
  bottomContent: {
    paddingTop: 50
  }
});

export default injectIntl(withStyles(styles, { withTheme: true })(RequestCallBackModal))
